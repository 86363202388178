@import '~plugin_frontend_core/fonts/icon-font';
@import '~plugin_frontend_core/utils/mixins/fonts';
@import '~plugin_frontend_core/variables';
@import '~plugin_frontend_core/mixins';

@import '~plugin_frontend_components/components/navigation/page-navigation';

.error-page {
    ul {
        @include list-reset;
        @extend .list-links__list;

        margin: 25px 0;
    }

    li {
        @extend .list-links__item;

        padding-bottom: 5px;
    }

    a {
        @extend .list-links__anchor;
    }
}
